import { render, staticRenderFns } from "./vIndex.vue?vue&type=template&id=dff865d4&scoped=true&"
import script from "./vIndex.vue?vue&type=script&lang=js&"
export * from "./vIndex.vue?vue&type=script&lang=js&"
import style0 from "./vIndex.vue?vue&type=style&index=0&id=dff865d4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff865d4",
  null
  
)

export default component.exports