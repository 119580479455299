
<template>
  <v-container>
    <h3 style="padding-bottom: 20px;">管理地址</h3>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->
    <v-col cols="12">  
      <v-alert 
      color="red lighten-2"
      v-show="show_alert"> {{ msg_alert }} </v-alert>
    </v-col>
    <v-row>
        <v-col cols="4">
          <v-radio-group
          v-model="option_tag"
          :change = "radChanged()"
          row
        >
          <v-radio
            label="全部"
            value="a"
            
          ></v-radio>
          <v-radio
            label="已标记"
            value="has_tag"
             
          ></v-radio>
          <v-radio
            label="未标记"
            value="not_tag"
             
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
          <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_from"
              label="选择开始日期"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_from"
            locale="zh-cn"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_to"
              label="选择结束日期"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_to"
            locale="zh-cn"
            @input="menu3 = false"
          ></v-date-picker>
        </v-menu>
        

      </v-col>
      <v-col cols="1"> <div style="padding: 12px;"><v-btn x-small @click="date_from='';date_to='';">清除日期</v-btn></div>  </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              
              label="查找备注"
              single-line
              hide-details
            ></v-text-field>
            <div style="padding: 6px;"> <v-btn @click="setSearch" color="primary">查  找</v-btn> </div>
          </v-card-title>

          <v-dialog v-model="dialogEditTag" max-width="600px">
          <v-card>
            <v-card-title class="text-h5">
              <div style="font-size: 5px;">区块 {{ editingHashId }}</div>
              <div style="font-size: 5px;">地址 {{ editingFromAddr }}</div>
            </v-card-title>
            
            <v-card-actions style="display:block">
              <v-spacer></v-spacer>
                  
                <v-btn v-for="(vname,index) in vnameList"  color="blue darken-1" text @click="saveItem(vname)" :key="'kkk'+index">{{vname}}</v-btn>  
                
              <!-- v-btn color="blue darken-1" text @click="saveItem('OK')">OK</v-btn -->
              <v-spacer></v-spacer>
              
            </v-card-actions>
            <v-card-actions>
              <div style="padding-right: 12px; font-size: 12px;">如无合适用户，可以新增</div><br>
              <v-text-field v-model="newAddrItem"></v-text-field>
              <v-btn color="yellow darken-1" text @click="saveNew('OK')">新增</v-btn>
              <v-btn color="yellow darken-1" text @click="cancelNew('OK')">取消</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      

          <v-data-table
            
            :headers="headers"
            :items="transList"
            :options.sync="options"
            :server-items-length="totalTrans"
            :loading="loading"
            :footer-props="{
                     itemsPerPageOptions: [ 5, 25, 50, 100] ,
                     'items-per-page-text': '每页展示'} "
            
          >
            <template v-slot:[`item.doEdit`]="{ item }">
            <v-chip
             color="secondary"
               dark
               @click="doEdit({item})"
            >
              修改 {{ item.none }}
            </v-chip>
          </template>
          <template v-slot:[`item.hashid`]="{ item }">
            {{ getHashIdAbbr(item.hashid) }}
          </template>

          <template v-slot:[`item.uname`]="{ item }">
            {{ item.uname }}
            <v-icon
              small
              class="mr-2"
              @click="editTag(item)"
            >
              mdi-pencil
            </v-icon>
          </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
 
<script>
import axios from 'axios';

export default {

  name: "vTyWorkSpace",

  data: () => ({
    logininfo: {},
    search : '',
    headers:[
    { text: 'Hash', value: 'hashid' },
    { text: '时间', value: 'atime' },
    { text: '转账地址', value: 'fromaddr' },
    { text: '接收地址', value: 'toaddr' },
    { text: '金额', value: 'amount' },
    { text: '所属用户', value: 'uname', width: "120px" },
    
    ],

    transList:[
      

    ],
    totalTrans : 0,
    loading : true,
    options: { itemsPerPage:50, page:1},
    //itemsPerPage: 50,
    //pagination: {page: 1, rowsPerPage: 25},
    dialogEditTag:false,

    vnameList : [],
    newAddrItem:'',
    editingHashId : '',
    editingFromAddr: '',
    //show alert login
    show_alert: false,
    msg_alert : '访问key出错，请重新获取',
    //options :
    option_tag : 'not_tag',

    date_from : '',
    date_to : '',
    menu2:'',
    menu3:'',
  
  }),

  methods:{
    cancelNew:function(dummy){
        console.log("dummy ", dummy);
        this.dialogEditTag = false;
    },
    saveNew:function(dummy){
        console.log("dummy ", dummy);
        if ( this.newAddrItem.length < 1){
          console.log('saveNew =>', '内容不合法');
          return ;
        }
        this.updateHashInfo( this.editingHashId, this.newAddrItem);

    },
    saveItem:function(uname){
      console.log('uname => ', uname);
      this.newAddrItem = uname 
      this.updateHashInfo( this.editingHashId, this.newAddrItem);

    },
    editTag:function(item){
        console.log( 'editTag = > ', item );
        this.editingHashId = item.hashid;
        this.editingFromAddr = item.fromaddr;
        this.loadTags(item.fromaddr);
        this.dialogEditTag = true;
    },
    doEdit:function(item ){
        console.log( 'doEdit', item );
        this.editDomain(item);
    },

    loadData: async function(){
      this.loading = true;
      let pageId = this.options.page  - 1;
      let pageNum = this.options.itemsPerPage; 
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
 
      let urlpath = '/api/list_trans?sx=' + this.$route.params.acckey+"&pageid="+pageId+"&pagenum="+pageNum;
      urlpath = urlpath + "&option_tag="+ this.option_tag;
      //set search key
        
      if ( this.search.length > 1  ){
        urlpath += "&option_search=" + this.search;
      }
      
      if ( this.date_from.length > 1 ){
        let option_datefrom = this.date_from + " 00:00:00";
        urlpath += "&option_datefrom=" + option_datefrom;
      }
      if ( this.date_to.length > 1 ){
        let option_dateto = this.date_to + " 23:59:59";
        urlpath += "&option_dateto=" + option_dateto;
      }
      await axios.get( urlpath ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            let rs = response.data.res;
            let dataItems = this.formatRes(rs);
            console.log( "data for table: ",  dataItems );
            this.transList = dataItems;
            //TODO find the true page size;
            this.totalTrans = response.data.count;
            this.loading = false;
        }else{
          if ( response.data.code == 401){
            this.show_alert = true;
            this.msg_alert = '访问key出错，请重新获取';
            this.loading=false;
          }else if( response.data.code == 503 ){
            this.show_alert = true;
            this.msg_alert = '后台服务似乎未启动或者出错';
            this.loading=false;
          }
        }
      }
      )
      .catch(err=>{
        console.log(err);
        this.show_alert = true;
        this.msg_alert = '后台服务似乎未启动或者访问出错，请检查或重试';
        this.loading=false;
      });
    },

    getHashIdAbbr: function(hashid){
      if( hashid != '' ){
        let len = hashid.length;
          return hashid.substring( len -8 );
      }
      return '';
    },

    formatRes: function(items){
      //console.log("items AK" , items);

      let li = [];
      for( const ia of items ){
        //console.log("IAIA ", ia);
        let it = {};
        it.hashid = ia[0];
        it.atime = ia[4];
        it.fromaddr = ia[3];
        it.toaddr = ia[1];
        it.amount = ia[5];  
        it.uname = ia[6];
        li.push( it );
      }
      return li; 
    },
    updateHashInfo:function(hashid, uname){
        console.log( "update set ", 'hashid ' + hashid + ' uname' + uname );
        let customConfig = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        let data = {"hashid": hashid, "tag_name": uname, "sx": this.$route.params.acckey }
        let jsData = JSON.stringify( data );
        axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
        axios.post( '/api/setTag', jsData, customConfig ).then( res=>{ 
          console.log("res==>", res);
          if ( res.status == 200 && res.data.code==200){
              //this.set_extra = res.data.data ;
              console.log("/setTag with name", uname); 
              this.newAddrItem = '';
              this.dialogEditTag = false;
              for(const vitem of this.transList){
                if ( vitem.hashid == hashid ){
                  vitem.uname = uname ;
                }
              }
              //load new
              console.log("SetTag begin new => " );
              this.loading = true ;
              this.loadData();

          }
        }).catch(
          err=>{
            console.log("err==>", err); 
          }
        ); 
        
    } ,

    loadTags:  function(addr){
      this.loading = true;
      
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      let urlpath = '/api/list_tags?sx=' + this.$route.params.acckey+"&addr="+addr ;
      axios.get( urlpath ).then(
      response=>{
        console.log("loadTags => ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            let rs = response.data.res;
            this.vnameList = rs ;
        }else{
          this.vnameList = [];
          
        }
      }
      )
      .catch(err=>{
        console.log(err);
      });
      this.loading = false;
    },

    radChanged(){
      //console.log("radChanged [option_tag] => ", this.option_tag  )
    },

    setSearch(){
      console.log("search text => ", this.search );
      this.loading=true;
      this.loadData();
    }


 
  },
  mounted: function(){
    let alt = 2;
    if (alt==1) return;
    
  },
  async created(){

    this.loadData();

  },

  watch: {
      options: {
        handler () {
          console.log("options", this.options);
          console.log("Go to page " , this.options.page );
          console.log("Go to page size " , this.options.itemsPerPage );
          this.loadData();
        },
        deep: true,
      },

      option_tag:{
        handler () {
            console.log("option_tag => ", this.option_tag );
            //TODO 
            this.transList = [];
            this.loading = true ;
            this.loadData();

        },
        deep: true,

      },
      search : {
        handler() {
          //console.log("my Search = > ", this.search );
        } , 
        deep : true ,
      }

    
    },
};

</script>

