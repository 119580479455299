<template>
  <v-app id="inspire">
    <v-card>
      <v-navigation-drawer  v-model="drawer" app>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Invoice/账单助手 </v-list-item-title>
            <v-list-item-subtitle>
              {{ tySysOwner }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item v-for="item in items" :key="item.title" link>
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-on:click="jmpPage(item.kk, item.acckey)">
                {{ item.title }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!--  -->
      </v-navigation-drawer>
    </v-card>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ tySysOwner }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!--  -->
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
  
<script>
//import axios from 'axios';

export default {
  data: () => ({
    drawer: false,
    items: [],
    tySysOwner: "超简单账单工具",
    logininfo : {}
  }),

  created: async function () {
    /*
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/api/profile' ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            this.logininfo = response.data.data;
        }else{
          window.location = '/login';
        }
      }
    )
    .catch(err=>{
      window.location = '/login';
      console.log(err);
    });
    */
    let acckey = this.$route.params.acckey;
    console.log("params is ", this.$route.params );
    console.log("path ",  this.$route.path  );
    this.tySysOwner;
    this.items = [
      { title: "首页", icon: "mdi-domain", color: "blue darken-2", kk: 'indexPage' , acckey: acckey },
      { title: "回到机器人", icon: "mdi-domain", color: "blue darken-2", kk: 'tg' , acckey: acckey },
      
       
    ];
    if ( this.$route.path == '/' ||  this.$route.path == ''  )
       this.jmpPage('indexPage')
  },

  methods: {
    jmpPage: function (path, acckey) {
      if ( path == 'tg'){
        window.location.href="https://t.me/imlittlek_bot";
      } 
      
      if ( path == 'indexPage'){
        this.$router.push({ name: "vIndex", params: { acckey: acckey } });
      } 
      
    },
  },
};
</script>