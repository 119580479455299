
<template>
  <v-container>
    <h3 style="padding-bottom: 20px;">管理地址</h3>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->
    <v-col cols="12">  
      <v-text-field label="http网址" v-model="url"></v-text-field>
      <v-text-field label="名称" v-model="uname"></v-text-field>
      <v-btn @click="addUrl" color="primary" >添加</v-btn> <v-btn @click="clearme"  >重置</v-btn> 
    </v-col>
    
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              
              label="查找备注"
              single-line
              hide-details
            ></v-text-field>
            <div style="padding: 6px;"> <v-btn @click="setSearch" color="primary">查  找</v-btn> </div>
          </v-card-title>

          
      

          <v-data-table
            
            :headers="headers"
            :items="transList"
            :options.sync="options"
            :server-items-length="totalTrans"
            :loading="loading"
            :footer-props="{
                     itemsPerPageOptions: [ 5, 25, 50, 100] ,
                     'items-per-page-text': '每页展示'} "
            
          >
            <!-- template v-slot:[`item.doEdit`]="{ item }">
            <v-chip
             color="secondary"
               dark
               @click="doEdit({item})"
            >
              修改 {{ item.none }}
            </v-chip>
          </template -->
          

          <template v-slot:[`item.uname`]="{ item }">
            {{ item.uname }}
            <!-- v-icon
              small
              class="mr-2"
              @click="editTag(item)"
            >
              mdi-pencil
            </v-icon -->
          </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
 
<script>
import axios from 'axios';

export default {

  name: "vUrlList",

  data: () => ({

    logininfo: {},
     

    headers:[
    { text: 'url', value: 'url' },
    { text: '名称', value: 'uname' },
    
    
    ],

    url: '',
    uname: '',
    //table content 
    transList: [], 
    totalTrans: 0,
    //table list 
    options: { itemsPerPage:50, page:1},
    search : '',
  }),

  methods:{
    clearme:function() {
      this.url  ='' ; 
      this.uname = '';
    },
    addUrl: function(){
        console.log("addUrl:method:" , this.url , this.uname);
        let customConfig = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        let data = {"url": this.url, "url_name": this.uname, "sx": this.$route.params.acckey }
        let jsData = JSON.stringify( data );
        axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
        axios.post( '/api/add_url', jsData, customConfig ).then( res=>{ 
          console.log("res==>", res);
          if ( res.status == 200 && res.data.code==200){
              //this.set_extra = res.data.data ;
              console.log("/api/add_url with name", this.uname); 
              
              console.log("SetTag begin new => " );
              this.loading = true ;
              this.loadData();

          }
        }).catch(
          err=>{
            console.log("err==>", err); 
          }
        ); 

    },

    loadData: async function(){
      this.loading = true;
      let pageId = this.options.page  - 1;
      let pageNum = this.options.itemsPerPage; 
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
 
      let urlpath = '/api/list_urls?sx=' + this.$route.params.acckey+"&pageid="+pageId+"&pagenum="+pageNum;
      urlpath = urlpath + "&option_tag="+ this.option_tag;
      //set search key
        
      if ( this.search.length > 1  ){
        urlpath += "&option_search=" + this.search;
      }
      
       
      await axios.get( urlpath ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            let rs = response.data.res;
            let dataItems = this.formatRes(rs);
            console.log( "data for table: ",  dataItems );
            this.transList = dataItems;
            //TODO find the true page size;
            this.totalTrans = response.data.count;
            this.loading = false;
        }else{
          if ( response.data.code == 401){
            this.show_alert = true;
            this.msg_alert = '访问key出错，请重新获取';
            this.loading=false;
          }else if( response.data.code == 503 ){
            this.show_alert = true;
            this.msg_alert = '后台服务似乎未启动或者出错';
            this.loading=false;
          }
        }
      }
      )
      .catch(err=>{
        console.log(err);
        this.show_alert = true;
        this.msg_alert = '后台服务似乎未启动或者访问出错，请检查或重试';
        this.loading=false;
      });
    },

 

    formatRes: function(items){
      //console.log("items AK" , items);

      let li = [];
      for( const ia of items ){
        //console.log("IAIA ", ia);
        let it = {};
        it.url = ia[1];
        it.uname = ia[2];
        
        li.push( it );
      }
      return li; 
    },

    radChanged(){
      //console.log("radChanged [option_tag] => ", this.option_tag  )
    },

    setSearch(){
      console.log("search text => ", this.search );
      this.loading=true;
      this.loadData();
    }


 
  },
  mounted: function(){
    let alt = 2;
    if (alt==1) return;
    
  },
  async created(){

    this.loadData();

  },

  watch: {
      options: {
        handler () {
          console.log("options", this.options);
          console.log("Go to page " , this.options.page );
          console.log("Go to page size " , this.options.itemsPerPage );
          this.loadData();
        },
        deep: true,
      },

      option_tag:{
        handler () {
            console.log("option_tag => ", this.option_tag );
            //TODO 
            this.transList = [];
            this.loading = true ;
            this.loadData();

        },
        deep: true,

      },
      search : {
        handler() {
          //console.log("my Search = > ", this.search );
        } , 
        deep : true ,
      }

    
    },
};

</script>

