<template>
  <v-container fill-height class="container--fluid">
    <v-row>
      <v-col class="col-lg-9 col-md-9">
        <div style="padding: 8px; border: 1px">
          <v-row>
            <v-col class="ma-2">
              <div style="padding: 8px; border: 1px">
                <div style="font-weight: bold; margin-bottom: 8px">
                  Seller 销售方
                </div>

                <v-text-field
                  v-model="Company1"
                  label="Company/公司名"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="Address1"
                  label="Address/地址"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="City1"
                  label="City/城市"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="State1"
                  label="State/州/省"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="Country1"
                  label="Country/国家"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="Zip1"
                  label="Zip/邮编"
                  class="ma-0 pa-0"
                ></v-text-field>
              </div>
            </v-col>
            <v-col class="ma-2">
              <div style="padding: 8px; border: 1px">
                <div style="font-weight: bold; margin-bottom: 8px">
                  Buyer 购买方
                </div>

                <v-text-field
                  v-model="Company2"
                  label="Company/公司名"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="Address2"
                  label="Address/地址"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="City2"
                  label="City/城市"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="State2"
                  label="State/州/省"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="Country2"
                  label="Country/国家"
                  class="ma-0 pa-0"
                ></v-text-field>
                <v-text-field
                  v-model="Zip2"
                  label="Zip/邮编"
                  class="ma-0 pa-0"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row class="ma-2">
            <v-col cols="6">
              <v-row align="center" justify="center">
                <v-col> Invoice No./发票编号 </v-col
                ><v-col
                  ><v-text-field
                    hide-details
                    v-model="InvNo"
                    label="Invoice No."
                    solo
                    class="ma-0 pa-0"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col> Invoice Date/发票日期 </v-col
                ><v-col
                  ><v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="InvDate"
                        label="发票开出日期"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="InvDate"
                      locale="zh-cn"
                      @input="menu2 = false"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col> Due Date/付款截止 </v-col
                ><v-col
                  ><v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="InvDue"
                        label="支付到期日期"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="InvDue"
                      locale="zh-cn"
                      @input="menu3 = false"
                    ></v-date-picker> </v-menu
                ></v-col> </v-row
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              <table style="border: none">
                <thead style="border: none; margin-bottom: 20px">
                  <tr>
                    <td style="">Description<br />描述</td>
                    <td style="width: 10%">Qty<br />数量</td>
                    <td style="width: 15%">Price<br />价格</td>
                    <td style="width: 15%">Sub Total<br />小计</td>
                    <td style="">&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="">
                      <v-text-field
                        hide-details
                        v-model="item1"
                        label="item/项目"
                        solo
                        class="ma-0 pa-0"
                      ></v-text-field>
                      <br />
                      <v-textarea
                        row-height="15"
                        rows="3"
                        v-model="desc1"
                        solo
                        label="desc/详细描述"
                      ></v-textarea>
                    </td>
                    <td style="">
                      <v-text-field
                        hide-details
                        v-model="qty1"
                        label="qty/数量"
                        solo
                        class="ma-0 pa-0"
                        @blur="tQtyBlur"
                      ></v-text-field>
                    </td>
                    <td style="">
                      <v-text-field
                        hide-details
                        v-model="price1"
                        label="price/价格"
                        solo
                        class="ma-0 pa-0"
                        @blur="tQtyBlur"
                      ></v-text-field>
                    </td>
                    <td style="">
                      <v-text-field
                        hide-details
                        v-model="subtotal1"
                        label="subtotal/小计"
                        solo
                        class="ma-0 pa-0"
                      ></v-text-field>
                    </td>
                    <td style="">
                      <v-btn
                        class="mx-2"
                        small
                        fab
                        dark
                        color="indigo"
                        v-if="false"
                        @click="doAddItems"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                      &nbsp;
                    </td>
                  </tr>

                  <tr v-for="item in extra_items" :key="item.extra_item_index">
                    <td style="">
                      <v-text-field
                        hide-details
                        v-model="item.item"
                        label="item/项目"
                        solo
                        class="ma-0 pa-0"
                      ></v-text-field>
                      <br />
                      <v-textarea
                        row-height="15"
                        rows="3"
                        solo
                        v-model="item.desc"
                        label="desc/详细描述"
                      ></v-textarea>
                    </td>
                    <td style="">
                      <v-text-field
                        hide-details
                        v-model="item.qty"
                        label="qty/数量"
                        solo
                        class="ma-0 pa-0"
                        @blur="subQtyBlur(item)"
                      ></v-text-field>
                    </td>
                    <td style="">
                      <v-text-field
                        hide-details
                        label="price/价格"
                        v-model="item.price"
                        solo
                        class="ma-0 pa-0"
                        @blur="subQtyBlur(item)"
                      ></v-text-field>
                    </td>
                    <td style="">
                      <v-text-field
                        v-model="item.subtotal"
                        label="subtotal/小计"
                        hide-details
                        solo
                        class="ma-0 pa-0"
                      ></v-text-field>
                    </td>
                    <td style="">
                      <v-btn
                        class="mx-2"
                        small
                        fab
                        @click="doDeleteItems(item)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <v-btn small @click="doAddItems">
                        Incr Item/增加项目
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>

          <div>&nbsp;</div>
          <v-divider></v-divider>

          <div style="padding: 8px; border: 1px; border-color: blue">
            <v-row>
              <v-col>
                <v-row>
                  <v-col
                    ><v-textarea
                      row-height="15"
                      rows="4"
                      hide-details
                      solo
                      v-model="iTerms"
                      label="Terms/使用条款"
                    >
                    </v-textarea
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><v-textarea
                      row-height="15"
                      rows="4"
                      hide-details
                      solo
                      v-model="iNotes"
                      label="Notes/备注"
                    >
                    </v-textarea
                  ></v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row align="center" justify="center" class="ma-2">
                  <v-col cols="4">
                    <span style="margin-right: 20px" class="mySubT">
                      Sub Total</span
                    >
                  </v-col>
                  <v-col> {{ sub_total }} &nbsp;&nbsp;{{ myccy }}</v-col>
                </v-row>

                <v-row align="center" justify="center" class="ma-2">
                  <v-col cols="4">
                    <span style="margin-right: 20px" class="mySubT">
                      Discount</span
                    ></v-col
                  >
                  <v-col>
                    <v-text-field
                      hide-details
                      solo
                      class="ma-0 pa-0"
                      v-model="discount"
                    ></v-text-field></v-col
                  ><v-col>
                    <v-switch
                      v-model="sw_discount"
                      :label="getdisLabel()"
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-row align="center" justify="center" class="ma-2">
                  <v-col cols="4">
                    <span style="margin-right: 20px" class="mySubT">
                      Tax</span
                    ></v-col
                  >
                  <v-col>
                    <v-text-field
                      hide-details
                      solo
                      class="ma-0 pa-0"
                      v-model="tax"
                    ></v-text-field></v-col
                  ><v-col>
                    <v-switch
                      v-model="sw_tax"
                      :label="getTaxLabel()"
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-row align="center" justify="center" class="ma-2">
                  <v-col cols="4">
                    <span style="margin-right: 20px" class="mySubT">
                      shipping</span
                    ></v-col
                  >
                  <v-col>
                    <v-text-field
                      hide-details
                      solo
                      class="ma-0 pa-0"
                      v-model="shipping"
                    ></v-text-field
                  ></v-col>
                </v-row>

                <v-divider></v-divider>
                <div style="margin-top: 20px" class="mySubT">
                  <span style="margin-right: 20px"> Total Amount </span>
                  <span> {{ total_amount }} &nbsp;&nbsp;{{ myccy }}</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col class="col-lg-3 col-md-3">
        <div>
          <v-row
            ><v-col
              ><v-alert type="warning" v-model="vAlert">
                {{ alert_msg }}</v-alert
              ></v-col
            ></v-row
          >
          <v-row>
            <v-col col="12">
              <v-btn style="width: 100%" @click="newInv()">
                +新增Invoice
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-btn color="primary" style="width: 100%" @click="genPdf()">
                输出PDF
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-btn color="purple" dark style="width: 100%" @click="saveInv()">
                保存Invoice
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div style="margin-top: 12px">
          <v-row>
            <v-col col="12"> <div>Currency/货币</div> </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-select
                :items="ccy_items"
                label="Solo field"
                solo
                v-model="myccy"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <div style="margin-top: 12px">
          <v-row>
            <v-col col="12"> <div>Saved invoice/历史</div> </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-select
                :items="invoice_items"
                label="invoice items"
                solo
                v-model="myinv"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
 
<style scoped>
td {
  vertical-align: top;
  padding-left: 5px;
  padding-bottom: 2px;
}

.testVV {
  color: #ffffff;
}
.mySubT {
  font-size: 16 px;
  font-weight: normal;
}
</style>
 
<script>
import axios from "axios";

export default {
  name: "vIndex",

  data: () => ({
    the_id : "",
    Company1: "",
    Address1: "",
    City1: "",
    State1: "",
    Country1: "",
    Zip1: "",
    Company2: "",
    Address2: "",
    City2: "",
    State2: "",
    Country2: "",
    Zip2: "",
    InvNo: "",
    menu2: "",
    menu3: "",
    InvDate: "",
    InvDue: "",

    //第一个项目
    item1: "",
    qty1: "",
    price1: "",
    subtotal1: "",
    desc1: "",

    extra_item_count: 0,
    extra_item_index: 1,
    extra_items: [],

    //总额小结
    sub_total: 0,

    //percent / amount
    discount_type: "percent",
    discount: 0,
    tax_type: "percent",
    tax: 0,

    shipping: 0,
    iTerms: "",
    iNotes: "",
    //最终额度 （含税 ，加运费）
    total_amount: 0,
    myccy: "USD",
    ccy_items: ["USD", "EUR", "GBP", "CNY", "HKD", "AUD"],
    myinv: "",
    invoice_items: [],

    //discount 、tax 是否百分比

    sw_discount: true,
    sw_tax: true,

    //
    vAlert: false,
    alert_msg: "",
    //校验用户身份的

    acckey: "",

    nameRules: [
      (value) => {
        if (value) return true;

        return "输入不能为空";
      },
      (value) => {
        if (value?.length >= 2 && value?.length < 64) return true;

        return "大于5个字符";
      },
    ],
    NumRules: [
      (value) => {
        if (value > 0 && value < 2147483647) return true;

        return "必须大于0并在合理范围";
      },
    ],

    //data table

    loading: true,
  }),

  methods: {
    getdisLabel: function () {
      return this.sw_discount == true ? "%" : this.myccy;
    },

    getTaxLabel: function () {
      return this.sw_tax == true ? "%" : this.myccy;
    },

    clearme: function () {
      this.url = "";
      this.uname = "";
    },

    jmpPage: function (path, acckey) {
      if (path == "transfer") {
        this.$router.push({ name: "vTransfer", params: { acckey: acckey } });
      }
      if (path == "team") {
        this.$router.push({ name: "vTeam", params: { acckey: acckey } });
      }
      if (path == "bills") {
        this.$router.push({ name: "vBills", params: { acckey: acckey } });
      }
      if (path == "indexPage") {
        this.$router.push({ name: "vIndex", params: { acckey: acckey } });
      }
    },

    doAddItems: function () {
      let vItem = {};
      vItem.item = "";
      vItem.desc = "";
      vItem.qty = "";
      vItem.price = "";
      vItem.subtotal = "";
      vItem.extra_item_index = this.extra_item_index++;
      this.extra_items.push(vItem);
    },

    doDeleteItems: function (item) {
      console.log("remove => ", item);
      let newArr = [];
      this.extra_items.forEach((o) => {
        o.extra_item_index != item.extra_item_index && newArr.push(o);
      });

      this.extra_items = newArr;
      console.log("remove OK");
    },

    wf_calitem1: function () {
      if (!parseFloat(this.price1)) this.price1 = 100;
      this.subtotal1 = parseInt(this.qty1) * parseFloat(this.price1);
    },

    tQtyBlur: function () {
      console.log("This->qty1", this.qty1);
      let vqty1 = parseInt(this.qty1);
      if (isNaN(vqty1)) vqty1 = 1;
      console.log("vqty1", vqty1);
      if (vqty1) this.qty1 = vqty1;
      console.log("This->qty1 after ", this.qty1);

      if (!parseFloat(this.price1)) this.price1 = 100;
    },

    subQtyBlur: function (item) {
      console.log("subblur item => ", item);
      console.log("This->qty", item.qty);
      let vqty1 = parseInt(item.qty);
      if (isNaN(vqty1)) vqty1 = 1;
      console.log("vqty1", vqty1);
      if (vqty1) item.qty = vqty1;
      console.log("This->qty1 after ", item.qty);

      if (!parseFloat(item.price)) item.price = 100;

      item.subtotal = item.qty * item.price;
    },

    calc_all: function () {
      //calc subtotal
      let sub_1 = this.qty1 * this.price1;

      for (let item of this.extra_items) {
        let st = item.qty * item.price;
        sub_1 += st;
      }

      this.sub_total = sub_1;

      let true_total = this.sub_total;
      if (!parseFloat(this.discount)) this.discount = 0;
      if (this.discount_type == "percent") {
        true_total = true_total * (1 - parseInt(this.discount) * 0.01);
      } else {
        true_total = true_total - parseFloat(this.discount);
      }
      if (true_total < 1) {
        return;
      }
      if (!parseFloat(this.tax)) this.tax = 0;
      if (this.tax_type == "percent") {
        true_total = true_total * (1 + parseInt(this.tax) * 0.01);
      } else {
        true_total = true_total + parseFloat(this.tax);
      }
      if (!parseFloat(this.shipping)) this.shipping = 0;
      this.total_amount = Math.round(true_total + parseFloat(this.shipping), 2);
    },

    genPdf: function () {
      //TODO genpdf use this.
      console.log("params router ", this.$route.params.acckey);
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      let url = "/api/genpdf";
      this.acckey = this.$route.params.acckey;
      //let fullurl =
      let mydata = this.$data;
      console.log("genPdf", JSON.stringify(this.$data));
      axios
        .post(url, mydata)
        .then((resp) => {
          console.log("Normal => ", { resp });
          if ( resp.status == 200 && resp.data.code == 200){
            let fid = resp.data.msg ;
            let url = axios.defaults.baseURL+"api/getpdf?fid="+fid;
            window.open(url, "_blank");
          }
        })
        .catch((err) => {
          console.log("Err => ", err);
        });
    },

    newInv() {
      (this.Company1 = ""),
        (this.Address1 = ""),
        (this.City1 = ""),
        (this.State1 = ""),
        (this.Country1 = ""),
        (this.Zip1 = ""),
        (this.Company2 = ""),
        (this.Address2 = ""),
        (this.City2 = ""),
        (this.State2 = ""),
        (this.Country2 = ""),
        (this.Zip2 = ""),
        (this.InvNo = ""),
        (this.menu2 = ""),
        (this.menu3 = ""),
        (this.InvDate = ""),
        (this.InvDue = ""),
        //第一个项目
        (this.item1 = ""),
        (this.qty1 = ""),
        (this.price1 = ""),
        (this.subtotal1 = ""),
        (this.desc1 = ""),
        (this.extra_item_count = 0),
        (this.extra_item_index = 1),
        (this.extra_items = []),
        //总额小结
        (this.sub_total = 0),
        //percent / amount
        (this.discount_type = "percent"),
        (this.discount = 0),
        (this.tax_type = "percent"),
        (this.tax = 0),
        (this.shipping = 0),
        (this.iTerms = ""),
        (this.iNotes = ""),
        //最终额度 （含税 ，加运费）
        (this.total_amount = 0),
        (this.myccy = "USD"),
        (this.ccy_items = ["USD", "EUR", "GBP", "CNY", "HKD", "AUD"]),
        (this.myinv = ""),
        (this.invoice_items = []),
        //discount 、tax 是否百分比

        (this.sw_discount = true),
        (this.sw_tax = true);
    },

    saveInv: function () {
      //保存发票信息
      console.log("saveINV params router ", this.$route.params.acckey);
      if (!this.$route.params.acckey) {
        //alert("OK, not login.");
        this.showAlert("未登录，无法操作/not login.", 4 );
        return;
      }
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      let url = "/api/saveinv";
      this.acckey = this.$route.params.acckey;
      //let fullurl =
      let mydata = this.$data;
      console.log("saveInv", JSON.stringify(this.$data));
      axios
        .post(url, mydata)
        .then((resp) => {
          console.log("saveInv Normal => ", { resp });
          if ( resp.status == 200 && resp.data.code == 200){
            this.the_id = resp.data.data.id ;
            this.InvNo  = resp.data.data.docid ;
          }
        })
        .catch((err) => {
          console.log("saveInv Err => ", err);
        });
    },


    loadLastData:function(){
      console.log("loadLastData params router ", this.$route.params.acckey);
      if (!this.$route.params.acckey) {
        //alert("OK, not login.");
        return;
      }
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      this.acckey = this.$route.params.acckey;
      let url = "/api/lastinv?acckey="+this.acckey;
      //let fullurl =
      //let mydata = this.$data;
      //console.log("saveInv", JSON.stringify(this.$data));
      axios
        .get(url)
        .then((resp) => {
          console.log("loadLastData Normal => ", { resp });
          if ( resp.status == 200 && resp.data.code == 200){
            this.loadInfo( resp.data.info );
          }
        })
        .catch((err) => {
          console.log("loadLastData Err => ", err);
        });
    },

    loadInfo: function( info )
    {
       let ds = JSON.parse( info.content );
       console.log("loadInfo=> ", ds); 
       (this.Company1 = ds.Company1),
        (this.Address1 = ds.Address1),
        (this.City1 = ds.City1),
        (this.State1 = ds.State1),
        (this.Country1 = ds.Country1),
        (this.Zip1 = ds.Zip1),
        (this.Company2 = ds.Company2),
        (this.Address2 = ds.Address2),
        (this.City2 = ds.City2),
        (this.State2 = ds.State2),
        (this.Country2 = ds.Country2),
        (this.Zip2 = ds.Zip2),
        (this.InvNo = ds.InvNo),
        (this.menu2 = ds.menu2),
        (this.menu3 = ds.menu3),
        (this.InvDate = ds.InvDate),
        (this.InvDue = ds.InvDue),
        //第一个项目
        (this.item1 = ds.item1),
        (this.qty1 = ds.qty1),
        (this.price1 = ds.price1),
        (this.subtotal1 = ds.subtotal1),
        (this.desc1 = ds.desc1),
        (this.extra_item_count = ds.extra_item_count),
        (this.extra_item_index = ds.extra_item_index),
        (this.extra_items = ds.extra_items ),
        //总额小结
        (this.sub_total = ds.sub_total),
        //percent / amount
        (this.discount_type = ds.discount_type ),
        (this.discount = ds.discount),
        (this.tax_type = ds.tax_type ),
        (this.tax = ds.tax),
        (this.shipping = ds.shipping),
        (this.iTerms = ds.iTerms),
        (this.iNotes = ds.iNotes),
        //最终额度 （含税 ，加运费）
        (this.total_amount = ds.total_amount),
        (this.myccy = ds.myccy),
        (this.ccy_items = ds.ccy_items ),
        (this.myinv = ds.myinv ),
        (this.invoice_items = ds.invoice_items ),
        //discount 、tax 是否百分比

        (this.sw_discount = ds.sw_discount ),
        (this.sw_tax = ds.sw_tax );

    },

    showAlert: function (msg, last_sec) {

      this.alert_msg = msg ;
      this.vAlert = true;
      last_sec;
      
      setTimeout( ()=>{ 
        this.alert_msg = '';
        this.vAlert = false ;
      } , last_sec * 1000 );
      
    },
  },

  mounted: function () {
    let alt = 2;
    if (alt == 1) return;
  },

  async created() {
    this.loadLastData();
  },

  watch: {
    qty1: function () {
      this.wf_calitem1();
      this.calc_all();
    },
    price1: function () {
      this.wf_calitem1();
      this.calc_all();
    },

    extra_items: {
      deep: true,
      handler: function () {
        this.calc_all();
      },
    },
    discount: function () {
      this.wf_calitem1();
      this.calc_all();
    },

    tax: function () {
      this.wf_calitem1();
      this.calc_all();
    },

    shipping: function () {
      this.wf_calitem1();
      this.calc_all();
    },

    sw_discount: function (oldvalue, newvalue) {
      if (newvalue) {
        this.discount_type = "value";
      } else {
        this.discount_type = "percent";
      }
      this.calc_all();
    },

    sw_tax: function (oldvalue, newvalue) {
      if (newvalue) {
        this.tax_type = "value";
      } else {
        this.tax_type = "percent";
      }

      this.calc_all();
    },
  },
};
</script>

