import VueRouter from "vue-router";
 
import vTyWorkSpace from './components/vTyWorkSpace'
//import vMonitor from './components/vMonitor'
import vUrlList from './components/vUrlList'
import vTransfer from './components/vTransfer'
import vTeam from './components/vTeam'
import vIndex from './components/vIndex'
import vBills from './components/vBills'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
} //以上三行是为了解决相同路由报错的问题，不用深究
 
export default new VueRouter({
    mode: 'history',
    routes: [

        {path: '/trans/', name: 'vTrans', component: vTyWorkSpace},

        {path: '/transfer/', name: 'vTransfer', component: vTransfer},

        {path: '/team/', name: 'vTeam', component: vTeam},

        {path: '/indexPage/:acckey', name: 'vIndex', component: vIndex},

        {path: '/bills/', name: 'vBills', component: vBills},

        {path: '/urllist/', name: 'vUrlList', component: vUrlList},
        
    ]
}) 
