<template>
  <v-container>
    <h3 style="padding-bottom: 20px;">我的账单</h3>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->
 
    
    <v-row dense>
    <v-col>
      <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="billList"
          :options.sync="options"
          :server-items-length="totalBill"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 25, 50, 100],
            'items-per-page-text': '每页展示',
          }"
        >
          <template v-slot:[`item.status`]="{ item }">
            {{ getStatusDesc(item.status) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
        </v-col>
    </v-row>
    
    

  </v-container>
</template>
 

 
<script>
import axios from 'axios';

export default {

  name: "vBills",

  data: () => ({
    
    dialog: false,
    setAlert: false,

    headers: [
      { text: "时间", value: "ctime" },
      { text: "ID", value: "trans_id" },
      { text: "账户户名", value: "account_name" },
      { text: "银行账号", value: "bank_num" },
      { text: "金额", value: "amount" },
      { text: "状态", value: "status" },
      { text: "团队", value: "belong_team" },
      { text: "操作人", value: "username" },
    ],

    billList: [],
    billMap: new Map(),
    totalBill: 0,
    loading: false,
    options: { itemsPerPage: 50, page: 1 },

    
  }),

  methods:{
    
    clearme:function() {
      this.url  ='' ; 
      this.uname = '';
    },
     
    loadData(){

      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL",  axios.defaults.baseURL);
      let url = '/api/me';
      axios.get( url ).then(
          response=>{
            console.log("get reponse " , response);
            if (response.status == 200){
              
              let svr_data = response.data;
              if (svr_data.code==303){
                  window.location.href= svr_data.data;
              }else{
                  console.log('data got ' ,  svr_data.data )
              }
              
            }else{
              console.log("not update.")
              
            }
          }
      ).catch(
          err=>{
            console.log("fire err ", err );
            
          }
      );

    },
    loadBills: function () {
      this.loading = true;
      this.inviteList = [];
      this.inviteMap = new Map();
      let that = this;
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/get_bill_list";
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[loadBill] -> ", svr_data.data);

              that.realLoadBills(svr_data.data);

            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },
    getStatusDesc: function (status) {
      if (status == "INIT") return "已发起";
      else if (status == "SUBMIT") return "支付中";
      else if (status == "OK") return "已成功";
      else if (status == "FAIL") return "已失败";
      else return "未知";
    },

    realLoadBills: function (data) {
      //console.log("realLoadBills", data);
      this.billList = [];
      for (let item of data) {
        //console.log("=>item", item);
        let di = {};
        di.username = item.username;
        di.account_name = item.account_name;
        di.amount = item.amount;
        di.bank_num = item.bank_num;
        di.id = item.id;
        di.status = item.status;
        di.trans_id = item.trans_id.substr(-6);
        di.ctime = item.ctime.substr(-20).substr(0,14).replace('T', ' ');
        this.billList.push(di);
      }
      this.totalBill = this.billList.length;
      //this.billList = data ;
    },

 
  },
  mounted: function(){
    let alt = 2;
    if (alt==1) return;
    
  },

  async created(){

     this.loadData();

     this.loadBills();

     console.log('params', this.$route.path);

  },

  watch: {
      

    
    },
};

</script>

